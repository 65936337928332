<template>
    <modal
        id="logout"
        ref="logout"
        :title="$t('modal.logout.heading')"
        type="general"
    >
        <div class="d-flex">
            <b-button
                variant="primary"
                @click="triggerLogout()"
            >
                {{ $t('modal.logout.yesButton') }}
            </b-button>
            <b-button
                variant="text"
                class="stroke"
                @click="closeModal()"
            >
                {{ $t('modal.logout.cancelButton') }}
            </b-button>
        </div>
    </modal>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex';

    import Modal from './Modal.vue';
    export default {
        components: { Modal },
        name: 'LogoutModal',
        methods: {
            ...mapMutations('auth', ['setConfirmed', 'setReSent']),
            ...mapActions('auth', ['logout']),
            closeModal() {
                this.$refs.logout.closeModal();
            },
            triggerLogout() {
                this.logout();
                this.setConfirmed(false);
                this.setReSent(false);
            },
        },
    };
</script>
